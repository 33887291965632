import { ReactElement, useState } from "react";
import { Box, Divider, Skeleton, Typography, styled } from "@mui/material";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { IoAdd, IoTrashOutline, IoEllipsisVertical } from "react-icons/io5";
import { Leader } from "../../../../store";
import colors from "../../../../colors";
import { PrivateContact } from "../../../../store/cabinetApi/generated/crm";
import { CabModal } from "@CabComponents/CabModal";
import { CabTooltip } from "@CabComponents/CabTooltip";


export interface PrivateContactsProps {
  leader: Leader;
  // openAddEditModal: (leaderId: number, contactId?: number) => void;
  leaderPrivateContacts: PrivateContact[] | null;
  onSetLeaderIdForAddContact: (leaderId: number) => void;
  onRemovePrivateContact: (leaderId: number, contactId: number) => Promise<void>;
  isLoading: boolean;
}

export const PrivateContacts = ({
  leader, leaderPrivateContacts, onSetLeaderIdForAddContact, onRemovePrivateContact, isLoading
}: PrivateContactsProps): ReactElement => {
  const [leaderContactIdPairToRemove, setLeaderContactIdPairtoRemove] = useState<[number, number] | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  
  const sharedBy = leader.shared_by ? `${leader.shared_by.first_name} ${leader.shared_by.last_name}` : undefined;

  const handleOpenRemoveModal = (leaderId: number, contactId: number) => {
    setLeaderContactIdPairtoRemove([leaderId, contactId]);
  };
  
  const handleRemovePrivateContact = async () => {
    if (leaderContactIdPairToRemove) {
      setIsRemoving(true);
      await onRemovePrivateContact(...leaderContactIdPairToRemove);
      setLeaderContactIdPairtoRemove(null);
      setIsRemoving(false);
    }
  };

  // If loading is done but there is no private contact list (even an empty one) for this leader,
  //    the user does not have access to this leader's private contacts.
  const hasPrivateContactsAccess = isLoading || leaderPrivateContacts != null;

  return (
    <>
      <StyledBox marginBottom={2}>
        <Box display='flex' justifyContent='space-between' marginBottom={1}>
          <Box display='flex' gap={1}>
            <CabAvatar
              color={leader.color}
              isShared={leader.is_shared}
              sharedBy={sharedBy}
              size="medium"
              src={leader.pic_url}
              name={`${leader.first_name} ${leader.last_name}`}
            />
            <Typography variant="h4">{`${leader.first_name} ${leader.last_name}`}</Typography>
          </Box>
          <CabTooltip wrapWithSpan title={
            hasPrivateContactsAccess ? "" : "You don't have access to see or manage this teammate's private contacts."}
          >
            <CabButton
              buttonType='text'
              color="primary"
              onClick={() => onSetLeaderIdForAddContact(leader.id)}
              sx={{ height: 30, marginRight: 1.5 }}
              icon={<CabIcon Icon={IoAdd} alt='Add' />}
              disabled={isLoading || !hasPrivateContactsAccess}
            >
              Add Private Contact
            </CabButton>
          </CabTooltip>
        </Box>
        {isLoading 
          ? <Skeleton height={50} width={'100%'} />
          : (leaderPrivateContacts || []).map((contact, idx) => {
            const contactLabel = contact.name 
              ? `${contact.name} (${contact.primary_email})` 
              : contact.primary_email || contact.id.toString();

            return (
              <Box key={`${contact.id}`}>
                {idx > 0 && (
                  <Divider />
                )}
                <Box paddingTop={1.5} paddingBottom={1.5} justifyContent='space-between' 
                  display='flex' alignItems='center' gap={1}>
                  <Typography variant="body1" sx={{overflowWrap: 'anywhere'}}>
                    {contactLabel}
                  </Typography>
                  <Box display='flex' gap={1} alignItems='center'>
                    <CabCollapseMenu 
                      buttons={<>
                        <StyledGroupButton
                          buttonType="text"
                          onClick={() => handleOpenRemoveModal(leader.id, contact.id)} 
                          icon={<CabIcon Icon={IoTrashOutline} color={colors.black900}/>}
                        >
                          Remove Private Contact
                        </StyledGroupButton>
                      </>}
                      popOverTitle=""
                      target={<CabIcon
                        Icon={IoEllipsisVertical} alt={contactLabel}
                        sx={{marginRight: 2, color: colors.black800, fontSize: 18}}
                      />}
                      buttonGroupColor="inherit"
                      buttonGroupVariant="outlined"
                      buttonGroupSx={{paddingTop: 1, paddingBottom: 1}}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </StyledBox>

      <CabModal open={!!leaderContactIdPairToRemove} title={"Remove Private Contact"}
        actionButtons={<>
          <CabButton
            buttonType="secondary"
            onClick={() => setLeaderContactIdPairtoRemove(null)}
          >
            Cancel
          </CabButton>
          <CabButton 
            onClick={handleRemovePrivateContact}
            disabled={isRemoving}
            color="error"
          >
            Yes, remove them from Private Contacts
          </CabButton>
        </>}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          Are you sure you want to make this contact visible to everyone in your organization?
        </Box>
      </CabModal>
    </>
  );
};

const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  minWidth: 500,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: 6,
  padding: 16,
  marginRight: 8
}));

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15,
  width: '100%'});
