import { useCallback, ReactNode, useState, useEffect, useMemo, useRef } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  Alert, Box, FormControl, FormHelperText, FormLabel, SelectChangeEvent, Stack, Typography
} from '@mui/material';
import { 
  Control, Controller, FormState, useForm, UseFormGetValues,
  UseFormReset, UseFormSetValue
} from 'react-hook-form';
import { 
  Leader, ORGANIZATION_PERMISSION, 
  OrganizationLicense, User, Organization, OrganizationSubmission
} from '../../../store';
import {
  CabDropdown, CabTextInput, CabIcon, CabButton, CabTooltip, CabModal, CabCardSelect, CabList,
} from '@CabComponents';
import colors from '../../../colors';
import { emailRegex, NEW_TIER, NEW_TIER_IDS, TIER, USER_ROLE } from '../../../constants';
import { GridData } from './types';
import { CabDropdownProps } from '@CabComponents/CabDropdown';
import { CabButtonProps } from '@CabComponents/CabButton';
import { IoCheckmarkCircleOutline, IoCloseCircleOutline, IoArrowBack, IoTrashOutline } from 'react-icons/io5';
import CabDataGrid from '@CabComponents/CabDataGrid';

const dropDownToAddLicenseText = (
  current_tier: NEW_TIER, tier: NEW_TIER,  missingLicenses: boolean, licenseCount: number
) => {
  if (current_tier === NEW_TIER.INDIVIDUAL && tier === NEW_TIER.INDIVIDUAL && licenseCount > 1) {
    return "Upgrade to Starter to add more licenses.";  
  }
  if (current_tier !== NEW_TIER.INDIVIDUAL && tier === NEW_TIER.INDIVIDUAL && licenseCount > 1) {
    return "Downgrade to one license before making this change";  
  }
  if (tier === NEW_TIER.STARTER) {
    return missingLicenses ? "You don't have any more unused licenses. Click to add more." : "";
  }
  return "Learn more about this tier from the Cabinet Team";
};

const validateUniqueEmail = (email: string, existingEmails: string[]) => {
  if (existingEmails.map(e => e.toLowerCase()).includes(email.toLowerCase())) {
    return 'Emails must be unique';
  }
  return true;
};

const CabButtonDisableOnClick = (props: CabButtonProps) => {
  const [clicked, setClicked] = useState(false);
  return <CabButton {...props}  onClick={(args) => {
    if (props.onClick) props.onClick(args);
    setClicked(true);
  }} disabled={props.disabled || clicked}/>;
};

export type LicenseGridProps = {
  control: Control<GridData>
  formState: FormState<GridData>
  setValue: UseFormSetValue<GridData>
  organization: Partial<Organization> & { id: Organization['id'] }
  handleSubmit: () => Promise<void>
  getValues: UseFormGetValues<GridData>
  reset: UseFormReset<GridData>
  setRows: (values: GridData["data"]) => void
  setCachedRows: (values: GridData["data"]) => void
  rows: GridData["data"]
  discardChanges: () => void
  usedStarterLicenses: number;
  usedIndividualLicenses: number;
  usedGrowthLicenses: number;
  usedPremierLicenses: number;
  setUsedStarter: (used: number) => void;
  setUsedIndividual: (used: number) => void;
  setUsedGrowth: (used: number) => void;
  setUsedPremier: (used: number) => void;
  onCreateLicense: (lic: OrganizationLicense) => Promise<void>;
  user?: User | null
  handleOpenLicenseModal: () => void
  org: OrganizationSubmission
  resendInvite: (license: OrganizationLicense) => void
};

type NewUserInfo<T extends USER_ROLE> = {
  info: UserInfo;
  role: T;
  link: T extends USER_ROLE.INDIVIDUAL ? UserLink : IndividualLink;
};

const defaultNewUser: NewUserInfo<USER_ROLE.INDIVIDUAL> | NewUserInfo<USER_ROLE.ASSISTANT> = {
  info: { name: '', email: '', licenseTier: NEW_TIER.BASIC },
  role: USER_ROLE.INDIVIDUAL,
  link: { assistant: 0, leader: 0 },
};

export default function LicenseGrid({
  control, formState, setValue, organization, handleSubmit, getValues, rows, setRows,
  discardChanges, usedStarterLicenses, usedIndividualLicenses, usedGrowthLicenses, usedPremierLicenses,
  setUsedIndividual, setUsedStarter, onCreateLicense, resendInvite, user,
  handleOpenLicenseModal, setCachedRows, reset, org, setUsedGrowth, setUsedPremier
}: LicenseGridProps) {

  const [starterLicenses, setStarterLicenses] = useState(0);
  const [individualLicenses, setIndividualLicenses] = useState(0);
  const [growthLicenses, setGrowthLicenses] = useState(0);
  const [premierLicenses, setPremierLicenses] = useState(0);
  const [page, setPage] = useState(0);
  const [newUserStep, setNewUserStep] = useState<number | null>(null);
  const submitHandler = useRef<(() => void) | null>(null);
  const [newUser, setNewUser] = useState(defaultNewUser);
  const [newUserRole, setNewUserRole] = useState<USER_ROLE | null>(null);

  const assistantsLeaders = Object.values(rows)
    .filter(row => row.user_role === USER_ROLE.ASSISTANT)
    .map(row => ({
      [row.user || '']: (row.assists_leaders || []).filter((l) => !l.leader_user)
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});
  
  useEffect(() => {
    const curUsedStarterLicenses = Object.values(rows)
      .filter(row => row.license_tier === NEW_TIER_IDS["STARTER"]).length;
    const curUsedIndividualLicenses = Object.values(rows).filter(
      row => row.license_tier === NEW_TIER_IDS["INDIVIDUAL"]
    ).length;
    const curUsedGrowthLicenses = Object.values(rows).filter(row => row.license_tier === NEW_TIER_IDS["GROWTH"]).length;
    const curUsedPremierLicenses = Object.values(rows).filter(
      row => row.license_tier === NEW_TIER_IDS["PREMIER"]
    ).length;
    setUsedStarter(curUsedStarterLicenses);
    setUsedIndividual(curUsedIndividualLicenses);
    setUsedGrowth(curUsedGrowthLicenses);
    setUsedPremier(curUsedPremierLicenses);
  // We need to look to see if the values of the object changes between instances
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [JSON.stringify(rows)]);
  
  useEffect(() => {
    setStarterLicenses(organization.license_count?.starter || 0);
    setIndividualLicenses(organization.license_count?.individual || 0);
    setGrowthLicenses(organization.license_count?.growth || 0);
    setPremierLicenses(organization.license_count?.premier || 0);
  }, [organization.license_count]);

  const createNewInvite = useCallback((info: NewUserInfo<USER_ROLE.INDIVIDUAL> | NewUserInfo<USER_ROLE.ASSISTANT>) => {
    const lowestIdRow = Object.values(rows).sort((a, b) => a.id - b.id)[0];
    const newId = (lowestIdRow && lowestIdRow.id < 0) ? lowestIdRow.id - 1 : -1;

    const newLicense = {
      id: newId,
      email: info.info.email,
      name: info.info.name,
      organization: organization.id,
      license_tier: -1,
      user: null,
      is_active: false,
      org_permissions_groups: [ORGANIZATION_PERMISSION.ANALYTICS_LABELS],
      tier: info.info.licenseTier,
      user_role: info.role,
      created_by: user?.id || -1,
      onsignup_assistant: info.role === USER_ROLE.INDIVIDUAL ? info.link.assistant || null : null,
      onsignup_leaders: info.role === USER_ROLE.INDIVIDUAL
        ? (info.link.leader ? [info.link.leader] : [])
        : info.link.individuals,
    };

    // const newRows: GridData["data"] = {
    //   ...rows, [newId]: newLicense
    // };
    // const key = `data.${newId}`;
    // setValue(key, newLicense, {shouldDirty: true, shouldValidate: false});
    // setRows(newRows);
    onCreateLicense(newLicense);
    setPage(0);
  // }, [setValue, organization.id, setRows, rows]);
  }, [onCreateLicense, organization.id, rows, user?.id]);


  const handleRegisterSubmit = useCallback((submitFunc: () => void) => {
    submitHandler.current = submitFunc;
  }, []);

  const handleSetUserInfo = (userInfo: UserInfo) => {
    setNewUser({ ...newUser, info: userInfo });
    setNewUserStep(newUserStep == null ? 0 : newUserStep + 1);
    submitHandler.current = null;
  };

  const handleSetUserRole = (userRole: USER_ROLE) => {
    if (userRole !== newUser.role) {
      if (userRole === USER_ROLE.INDIVIDUAL) {
        setNewUser({ ...newUser, role: USER_ROLE.INDIVIDUAL, link: { assistant: 0, leader: 0 } });
      } else {
        setNewUser({ ...newUser, role: USER_ROLE.ASSISTANT, link: { individuals: [] } });
      }
    }
    setNewUserStep(newUserStep == null ? 0 : newUserStep + 1);
    submitHandler.current = null;
  };

  const handleLinkAssistant = async (link: UserLink) => {
    createNewInvite({ ...newUser, role: USER_ROLE.INDIVIDUAL, link });
    await handleSubmit();
    setNewUserStep(null);
    setNewUserRole(null);
    setNewUser(defaultNewUser);
    submitHandler.current = null;
  };

  const handleLinkIndividual = async (link: IndividualLink) => {
    createNewInvite({ ...newUser, role: USER_ROLE.ASSISTANT, link });
    await handleSubmit();
    setNewUserRole(null);
    setNewUserStep(null);
    setNewUser(defaultNewUser);
    submitHandler.current = null;
  };

  const handleNewUserNext = () => {
    submitHandler.current?.();
  };

  const handleNewUserCancel = () => {
    submitHandler.current = null;
    setNewUserRole(null);
    setNewUser(defaultNewUser);
    setNewUserStep(null);
  };

  const handleNewUserBack = () => {
    submitHandler.current = null;
    setNewUserStep(newUserStep == null || newUserStep <= 0 ? 0 : newUserStep - 1);
  };

  const renderUnsubmittedChanges = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({ row: { user: licenseUser } }) => {
      return <Box sx={{width:"100%"}} display="flex" justifyContent="center">
        {licenseUser ? 
          <CabIcon sx={{color: colors.greenPrimary}} size="large"  Icon={IoCheckmarkCircleOutline}/>
          :
          <CabIcon sx={{color: colors.white400}}  size="large"  Icon={IoCloseCircleOutline}/>
        }
      </Box>;
    }, []);
  
  const renderDeleteButton = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({ row }) => {
      const removeRecord = () => {
        const dataRows = rows;
        const newRows: {[id: number]: OrganizationLicense} = {};

        Object.values(dataRows).forEach((datum) => {
          if (datum.id !== row.id && datum.id !== 0) {
            newRows[datum.id] = datum;
          }
        });

        const removedRow = dataRows[row.id];

        if (removedRow.license_tier === 100) {
          setUsedIndividual(usedIndividualLicenses - 1);
        } else if (removedRow.license_tier === 101) {
          setUsedStarter(usedStarterLicenses - 1);
        } else if (removedRow.license_tier === 102) {
          setUsedGrowth(usedGrowthLicenses - 1);
        } else if (removedRow.license_tier === 103) {
          setUsedPremier(usedPremierLicenses - 1);
        }

        const newLicense = {
          ...removedRow,
          id: 0,
        };
    

        const key = `data.${row.id}`;
        setValue(key, newLicense, { shouldDirty: true, shouldValidate: false });
        setRows(newRows);
      };
      const disabled = row.user !== user?.id;
      return <Box 
        sx={{width:"100%", ...(!disabled ? {opacity: 0.5} : undefined)}} display="flex" justifyContent="center"
      >
        <CabIcon 
          color={colors.redError}
          size="large"
          Icon={IoTrashOutline}
          onClick={() => disabled ? removeRecord() : undefined}
        />
      </Box>;
    }, [
      user?.id, rows, setValue, setRows, setUsedIndividual,
      usedIndividualLicenses, setUsedStarter, usedStarterLicenses,
      setUsedGrowth, usedGrowthLicenses, setUsedPremier, usedPremierLicenses
    ]);

  const renderEmailCell = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({ row }) => {
      
      if (row.user) {
        return <Typography>{row.email}</Typography>;
      }
      
      return <Controller
        control={control}
        name={`data.${row.id}.email`}
        rules={{
          required: true,
          pattern: emailRegex,
          validate: (email) => validateUniqueEmail(
            email,
            Object.values(getValues('data') || {}).filter(v => v.id !== row.id).map(v => v.email),
          ),
        }}
        render={
          ({field: {value, onChange}, fieldState: {error}}) => <Box position="relative"><CabTextInput
            value={value}
            onChange={onChange}
            disabled={row.user === user?.id}
            formControlSx={{width: error ? "230px" : "280px"}}
          />
          {error && <CabTooltip title={error.message || ""} placement="top">
            <Alert
              severity='error'
              sx={{position: "absolute", padding: "2px 16px", top: 0, right: -50, "& .MuiAlert-icon": {marginRight: 0}}}
            />
          </CabTooltip>}
          </Box>
        }
      />;
    }, [control, getValues, user?.id]);

  const licenseCount = useMemo(
    () => individualLicenses + starterLicenses + growthLicenses + premierLicenses,
    [growthLicenses, individualLicenses, premierLicenses, starterLicenses]
  );

  const currentTier = useMemo(
    () => {
      if (individualLicenses > 0) {
        return NEW_TIER.INDIVIDUAL;
      } else if (starterLicenses > 0) {
        return NEW_TIER.STARTER;
      } else if (growthLicenses > 0) {
        return NEW_TIER.GROWTH;
      } else if (premierLicenses > 0) {
        return NEW_TIER.PREMIER;
      } 
      return NEW_TIER.BASIC;
    },

    [
      growthLicenses, individualLicenses,
      premierLicenses, starterLicenses
    ]
  );

  const licenseTierOptions = useMemo(() => {
    const licenseTierOptionsList: CabDropdownProps<TIER>["options"] = [
      { 
        value: NEW_TIER.BASIC, 
        label: "No License",
        disabled: false
      },
      { 
        value: NEW_TIER.INDIVIDUAL, 
        label: NEW_TIER.INDIVIDUAL,
        disabled: individualLicenses === 0 || licenseCount > 1,
        tooltipText: dropDownToAddLicenseText(
          currentTier, NEW_TIER.INDIVIDUAL, individualLicenses <= usedIndividualLicenses, licenseCount
        )
      },
      { 
        value: NEW_TIER.STARTER, 
        label: NEW_TIER.STARTER,
        disabled: starterLicenses === 0,
        tooltipText: dropDownToAddLicenseText(
          currentTier, NEW_TIER.STARTER, starterLicenses <= usedStarterLicenses, licenseCount
        )
      }
    ];
    
    licenseTierOptionsList.push({ 
      value: NEW_TIER.GROWTH, 
      label: NEW_TIER.GROWTH,
      disabled: growthLicenses === 0,
      tooltipText: dropDownToAddLicenseText(
        currentTier, NEW_TIER.GROWTH, growthLicenses <= usedGrowthLicenses, licenseCount
      )
    });
  
  
    licenseTierOptionsList.push({ 
      value: NEW_TIER.PREMIER, 
      label: NEW_TIER.PREMIER,
      disabled: premierLicenses === 0,
      tooltipText: dropDownToAddLicenseText(
        currentTier, NEW_TIER.PREMIER, premierLicenses <= usedPremierLicenses, licenseCount
      )
    });
    
    
    return licenseTierOptionsList;
  }, [individualLicenses, licenseCount, currentTier, usedIndividualLicenses, starterLicenses,
    usedStarterLicenses, growthLicenses, usedGrowthLicenses, premierLicenses, usedPremierLicenses
  ]);
  
  const renderTierCell = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({row}) => {
      const disabled = false;
      return <>
        <Controller
          control={control}
          name={`data.${row.id}.tier`}
          render={
            ({field: {value, onChange}}) => {

              const manageLicenseStateOnChange = (e: SelectChangeEvent<TIER>) => {
                let licenseTier = -1;
                let tier = NEW_TIER.BASIC;
                if (value === NEW_TIER.INDIVIDUAL) {
                  setUsedIndividual(usedIndividualLicenses - 1);
                } else if (value === NEW_TIER.STARTER) {
                  setUsedStarter(usedStarterLicenses - 1);
                }

                if (e.target.value === NEW_TIER.INDIVIDUAL) {
                  const newIndividualCount = usedIndividualLicenses + 1;
                  licenseTier = NEW_TIER_IDS["INDIVIDUAL"];
                  tier = NEW_TIER.INDIVIDUAL;
                  if (newIndividualCount > individualLicenses) {
                    setCachedRows(rows);
                    handleOpenLicenseModal();
                  } else {
                    setUsedIndividual(usedIndividualLicenses + 1);
                  }
                } else if (e.target.value === NEW_TIER.STARTER) {
                  licenseTier = NEW_TIER_IDS["STARTER"];
                  tier = NEW_TIER.STARTER;
                  const newStarterCount = usedStarterLicenses + 1;
                  if (newStarterCount > starterLicenses) {
                    setCachedRows(rows);
                    handleOpenLicenseModal();
                  } else {
                    setUsedStarter(usedStarterLicenses + 1);
                  }
                }

                onChange(e);
                setRows({...rows, [row.id]: {...rows[row.id], license_tier: licenseTier, tier: tier}});
              };

              return <CabDropdown<TIER>
                value={value ? value : NEW_TIER.BASIC}
                disabled={disabled}
                sx={{ width: "100%" }}
                onChange={manageLicenseStateOnChange}
                options={licenseTierOptions}
              />;
            }
          }
        />
      </>;
    }, [
      control, licenseTierOptions, setRows, rows, setUsedIndividual, usedIndividualLicenses, setUsedStarter,
      usedStarterLicenses, individualLicenses, setCachedRows, handleOpenLicenseModal, starterLicenses
    ]);

  const renderUserRoleCell = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({ row }) => {
      const tooltip = `To change this account's role, please contact help@joincabinet.com`;
      const userRoleOptions = [
        { value: USER_ROLE.ASSISTANT, label: "Yes", disabled: false },
        { value: USER_ROLE.INDIVIDUAL, label: "No", disabled: false },
      ];
      const disabled = false;
      return <>
        <Controller
          control={control}
          name={`data.${row.id}.user_role`}
          render={
            ({ field: { value, onChange } }) => {

              return <CabTooltip
                title={disabled ? tooltip : ''}
                placement="bottom"
                wrapWithSpan
                sx={{width: '100%'}}
              >
                <CabDropdown<USER_ROLE>
                  value={value ? value : USER_ROLE.INDIVIDUAL}
                  disabled={disabled}
                  sx={{ width: "100%" }}
                  onChange={onChange}
                  options={userRoleOptions}
                />
              </CabTooltip>;
            }
          }
        />
      </>;
    }, [control]);

  const renderOrgPermissionRoles = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({ row }) => {
      const tooltip = `
      To change this account's role, please contact an administrator or manager with your organization
      `;
      const orgPermissionOptions = [
        { 
          value: ORGANIZATION_PERMISSION.ADMIN, 
          label: "Admin", 
          disabled: !user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN),
          subtext: "Full permissions to the organization, including changing roles for other users."
        },
        { 
          value: ORGANIZATION_PERMISSION.BILLING, 
          label: "Billing", 
          disabled: !(
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN) || 
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.MANAGER)
          ),
          subtext: "Can see and change billing information and subscription details"
        },
        { 
          value: ORGANIZATION_PERMISSION.MANAGER, 
          label: "Team", 
          disabled: !(
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN) || 
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.MANAGER)
          ),
          subtext: "Can manage users"
        },
        { 
          value: ORGANIZATION_PERMISSION.ANALYTICS_LABELS, 
          label: "Analytics Labels", 
          disabled: !(
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN) || 
            user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.MANAGER)
          ),
          subtext: "Can create, edit or delete calendar analytics labels"
        },
      ];

      const disabled = row.user === user?.id || (
        row.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN) &&
        !user?.active_license.org_permissions_groups.includes(ORGANIZATION_PERMISSION.ADMIN)
      );

      return <>
        <Controller
          control={control}
          name={`data.${row.id}.org_permissions_groups`}
          render={
            ({ field: { value, onChange } }) => {
              return <CabTooltip
                title={disabled ? tooltip : ''}
                placement="bottom"
                wrapWithSpan
                sx={{ width: '100%' }}
              >
                <CabDropdown<ORGANIZATION_PERMISSION>
                  value={value ? value : []}
                  sx={{ width: "100%" }}
                  disabled={disabled}
                  placeholder='Add permissions'
                  multiple
                  menuItemSx={{maxWidth: "400px", whiteSpace: "pre-wrap"}}
                  onChange={(event) => disabled ? undefined : onChange(event.target?.value)}
                  options={orgPermissionOptions}
                />
              </CabTooltip>;
            }
          }
        />
      </>;
    }, [control, user?.active_license.org_permissions_groups, user?.id]);

  const unCommitedChanges = useCallback<(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>) => ReactNode
  )>(({row}) => {
      return <>
        {formState.dirtyFields.data && formState.dirtyFields.data[row.id] ? 
          <Typography sx={{color: colors.white600}}>Changes Pending</Typography>
          :
          <></>
        }
      </>;
    }, [formState.dirtyFields.data]);


  const renderNameCell = useCallback(
    (props: GridRenderCellParams<OrganizationLicense, ReactNode>): ReactNode => {
      
      return (props.row.user || !organization.is_on_contract) ? 
        <>{props.row.name}</> : <CabButtonDisableOnClick onClick={() => resendInvite(props.row)}>
          Resend Invite
        </CabButtonDisableOnClick>;
    }, [organization.is_on_contract, resendInvite]);

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: 'Name',
      width: 225,
      display: "flex",
      editable: false,
      sortable: true,
      renderCell: renderNameCell
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      display: "flex",
      editable: false,
      sortable: true,
      renderCell: renderEmailCell
    },
    ...(user?.active_license.tier !== NEW_TIER.INDIVIDUAL ? [{
      field: 'license_tier',
      headerName: 'License Tier',
      width: 175,
      display: "flex",
      editable: false,
      sortable: true,
      renderCell: renderTierCell
    } satisfies GridColDef] : []),
    {
      field: 'user_role',
      headerName: 'Is Assistant?',
      width: 175,
      display: "flex",
      editable: false,
      sortable: true,
      renderCell: renderUserRoleCell
    },
    {
      field: 'permissions',
      headerName: 'Permission Level',
      width: 175,
      display: "flex",
      editable: false,
      sortable: true,
      renderCell: renderOrgPermissionRoles
    },
    {
      width: 130,
      display: "flex",
      editable: false,
      groupable: false,
      sortable: false,
      align:"right",
      headerAlign: "right",
      pinnable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: renderUnsubmittedChanges,
      field: 'submitted',
      headerName: 'Account Created',
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 72,
      display: "flex",
      editable: false,
      sortable: false,
      renderCell: renderDeleteButton
    },
    {
      field: 'uncomitted',
      headerName: '',
      width: 150,
      display: "flex",
      editable: false,
      sortable: false,
      renderCell: unCommitedChanges
    },
    {
      field: '',
      headerName: '',
      minWidth: 0,
      display: "flex",
      flex: 1,
      disableColumnMenu: true,
      resizable: false,
      hideable: false,
      filterable: false,
      sortable: false,
    },
  ], [renderDeleteButton, renderEmailCell, renderNameCell, renderOrgPermissionRoles, renderTierCell, 
    renderUnsubmittedChanges, renderUserRoleCell, unCommitedChanges, user?.active_license.tier]);

  const dataRows = useMemo(() => ( 
    Object.values(rows).sort((a, b) => a.id - b.id) 
  ), [rows]);

  const paginationModel = useMemo(() => ({
    page,
    pageSize: 10,
  }), [page]);

  const loading = useMemo(() => (Object.keys(rows).length === 0), [rows]);

  const slots = useMemo(() => ({
    noRowsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        There are no users in your organization
      </Stack>
    ),
    toolbar: () => <Box 
      sx={{margin: 1, width: "100%", paddingRight:2 }}
      display="flex"
      justifyContent="space-between"
    >
      <CabButton type="button" onClick={() => setNewUserStep(0)} sx={{marginRight: 1}}>
        Add User
      </CabButton>
      <Box>
        <CabButton
          type="button"
          buttonType='tertiary'
          sx={{marginRight: 1}}
          disabled={!formState.isDirty}
          onClick={() => discardChanges()}
        >
          Discard Changes
        </CabButton>
        <CabButton
          type="button"
          disabled={!formState.isDirty}
          onClick={() => handleSubmit()}
        >
          Apply Changes
        </CabButton>
      </Box>
    </Box>
  }), [discardChanges, formState.isDirty, handleSubmit]);

  return (
    <>
      <CabDataGrid
        rows={dataRows}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        autoHeight
        pageSizeOptions={[10]}
        onPaginationModelChange={(model) => setPage(model.page)}
        disableRowSelectionOnClick
        loading={loading}
        slots={slots}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: 'unset'
          },
          "&": {
            border: `1px solid ${colors.black100}`
          },
          "& .MuiDataGrid-virtualScroller": {
            border: 0
          }
        }}
      />

      <CabModal
        open={newUserStep != null}
        onClose={handleNewUserCancel}
        title="Add User"
        text={newUserStep === 1
          ? 'How will this person use Cabinet?'
          : (newUserStep === 2  
            ? (newUser.role === USER_ROLE.INDIVIDUAL
              ? "If this user has an assistant on Cabinet already, you can connect them below"
              : "Does this assistant support any Individual who already have a Cabinet account?")
            : '')}
        sx={{ '& .MuiDialog-paper': { width: 690 } }}
        actionButtons={(
          <Box display="flex" justifyContent="space-between" width="100%">
            {(newUserStep || 0) > 0 ? (
              <CabButton
                onClick={handleNewUserBack}
                buttonType="tertiary"
                icon={<CabIcon Icon={IoArrowBack} />}
              >
                Back
              </CabButton>
            ) : <Box />}
            <Box display="flex" gap={1}>
              <CabButton onClick={handleNewUserCancel} buttonType="secondary">Cancel</CabButton>
              <CabButton 
                onClick={handleNewUserNext}
                disabled={newUserStep === 1 && newUserRole === null}
              >
                Continue
              </CabButton>
            </Box>
          </Box>
        )}
      >
        {newUserStep === 0 && (
          <AddUserInfo
            defaultInfo={newUser.info}
            isStarterOrganization={starterLicenses > 0}
            isGrowthOrganization={growthLicenses > 0}
            isPremierOrganization={premierLicenses > 0}
            hasIndividualLicensesAvailable={individualLicenses > usedIndividualLicenses}
            hasStarterLicensesAvailable={starterLicenses > usedStarterLicenses}
            hasGrowthLicensesAvailable={growthLicenses > usedGrowthLicenses}
            hasPremierLicensesAvailable={premierLicenses > usedPremierLicenses}
            existingEmails={Object.values(getValues('data') || {}).map(v => v.email)}
            onRegisterSubmit={handleRegisterSubmit}
            onSubmit={handleSetUserInfo}
            handleOpenLicenseModal={handleOpenLicenseModal}
            licenseCount={licenseCount}
            currentTier={currentTier}
          />
        )}
        {newUserStep === 1 && (
          <AddUserRole
            defaultRole={newUserRole}
            onRegisterSubmit={handleRegisterSubmit}
            onSubmit={handleSetUserRole}
            setNewUserRole={setNewUserRole}
          />
        )}
        {newUserStep === 2 && (newUser.role === USER_ROLE.INDIVIDUAL ? (
          <AddUserLinkAssistant
            defaultUserLink={newUser.link}
            onRegisterSubmit={handleRegisterSubmit}
            onSubmit={handleLinkAssistant}
            assistants={Object.values(rows).filter(a => a.user_role === USER_ROLE.ASSISTANT)}
            assistantsLeaders={assistantsLeaders}
          />
        ) : (
          <AddUserLinkIndividual
            defaultIndividualLink={newUser.link}
            onRegisterSubmit={handleRegisterSubmit}
            onSubmit={handleLinkIndividual}
            individuals={Object.values(rows).filter(a => a.user_role === USER_ROLE.INDIVIDUAL)}
          />
        ))}
      </CabModal>
    </>
  );
}

type UserInfo = {
  name: string;
  email: string;
  licenseTier: TIER;
};

interface AddUserInfoProps {
  defaultInfo: UserInfo;
  hasIndividualLicensesAvailable: boolean;
  hasStarterLicensesAvailable: boolean;
  hasGrowthLicensesAvailable: boolean;
  hasPremierLicensesAvailable: boolean;
  existingEmails: string[],
  onRegisterSubmit: (submitFunc: () => void) => void;
  onSubmit: (userInfo: UserInfo) => void;
  handleOpenLicenseModal: () => void
  isStarterOrganization: boolean,
  licenseCount: number
  currentTier: NEW_TIER
  isPremierOrganization: boolean
  isGrowthOrganization: boolean
}

const licenseUnavailableText = (tier: TIER) => (
  `There are no more ${tier === NEW_TIER.STARTER ? 'Starter' : 'Individual'} Licenses available.
  You may add more through the Licenses table.`
);

const AddUserInfo = ({
  defaultInfo, hasIndividualLicensesAvailable, hasStarterLicensesAvailable, existingEmails, onRegisterSubmit, onSubmit,
  handleOpenLicenseModal, isStarterOrganization, licenseCount, currentTier, hasPremierLicensesAvailable,
  hasGrowthLicensesAvailable, isGrowthOrganization, isPremierOrganization
}: AddUserInfoProps) => {
  const { control, handleSubmit } = useForm({ defaultValues: defaultInfo });

  useEffect(() => onRegisterSubmit(handleSubmit(onSubmit)), [onRegisterSubmit, handleSubmit, onSubmit]);

  const licenseTierOptions = useMemo(() => {
    const individualDisabled = !hasIndividualLicensesAvailable || isStarterOrganization;
    const starterDisabled = !hasStarterLicensesAvailable || !isStarterOrganization;
    const growthDisabled = !hasGrowthLicensesAvailable || !isGrowthOrganization;
    const premierDisabled = !hasPremierLicensesAvailable || !isPremierOrganization;
    const licenseTierOptionsList = [
      { value: NEW_TIER.BASIC, label: "No License", disabled: false },
      {
        value: NEW_TIER.INDIVIDUAL,
        label: "Individual",
        disabled: individualDisabled,
        tooltipText: hasIndividualLicensesAvailable ? '' : (
          !individualDisabled ? dropDownToAddLicenseText(
            currentTier, NEW_TIER.INDIVIDUAL, !individualDisabled, licenseCount
          ) : 
            licenseUnavailableText(NEW_TIER.INDIVIDUAL)
        ),
      },
      {
        value: NEW_TIER.STARTER,
        label: "Starter",
        disabled: starterDisabled,
        tooltipText: hasStarterLicensesAvailable ? '' : (
          !starterDisabled ? 
            dropDownToAddLicenseText(currentTier, NEW_TIER.STARTER, !starterDisabled, licenseCount) :
            licenseUnavailableText(NEW_TIER.STARTER, )
        ),
      },
      {
        value: NEW_TIER.GROWTH,
        label: "Growth",
        disabled: growthDisabled,
        tooltipText: hasGrowthLicensesAvailable ? '' : (
          !growthDisabled ? 
            dropDownToAddLicenseText(currentTier, NEW_TIER.GROWTH, !growthDisabled, licenseCount) :
            licenseUnavailableText(NEW_TIER.GROWTH, )
        ),
      },
      {
        value: NEW_TIER.PREMIER,
        label: "Premier",
        disabled: premierDisabled,
        tooltipText: hasPremierLicensesAvailable ? '' : (
          !premierDisabled ? 
            dropDownToAddLicenseText(currentTier, NEW_TIER.PREMIER, !premierDisabled, licenseCount) :
            licenseUnavailableText(NEW_TIER.PREMIER, )
        ),
      },
    ];

    return licenseTierOptionsList;
  }, [
    currentTier, hasIndividualLicensesAvailable,
    hasStarterLicensesAvailable, isStarterOrganization,
    licenseCount, hasGrowthLicensesAvailable, hasPremierLicensesAvailable,
    isPremierOrganization, isGrowthOrganization
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Controller control={control} name="email"
            rules={{
              pattern: { value: emailRegex, message: 'Must be a valid email' },
              validate: (email) => validateUniqueEmail(email, existingEmails),
              required: { value: true, message: 'Required' },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (<>
              <CabTextInput {...field} inputRef={ref} />
              <FormHelperText error>{fieldState.error && fieldState.error.message}</FormHelperText>
            </>)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>License Tier</FormLabel>
          <Controller control={control} name="licenseTier" render={({ field: { ref, onChange, ...field } }) => (
            <CabDropdown<TIER>
              {...field}
              sx={{ width: "100%" }}
              options={licenseTierOptions}
              onChange={(e) => {
                if (e.target.value === NEW_TIER.STARTER && isStarterOrganization) {
                  if (hasStarterLicensesAvailable) {
                    onChange(e);
                  } else {
                    handleOpenLicenseModal();
                  }
                }
                if (e.target.value === NEW_TIER.INDIVIDUAL && !isStarterOrganization) {
                  if (hasIndividualLicensesAvailable) {
                    onChange(e);
                  } else {
                    handleOpenLicenseModal();
                  }
                }
                if (e.target.value === NEW_TIER.GROWTH) {
                  if (hasGrowthLicensesAvailable) {
                    onChange(e);
                  }
                }
                if (e.target.value === NEW_TIER.PREMIER) {
                  if (hasPremierLicensesAvailable) {
                    onChange(e);
                  }
                }
              }}
            />
          )} />
        </FormControl>
      </Stack>
      <input type="submit" hidden />
    </form>
  );
};

interface AddUserRoleProps {
  defaultRole: USER_ROLE | null;
  onRegisterSubmit: (submitFunc: () => void) => void;
  onSubmit: (userRole: USER_ROLE) => void;
  setNewUserRole: (role: USER_ROLE) => void;
}

const AddUserRole = ({ defaultRole, onRegisterSubmit, onSubmit, setNewUserRole }: AddUserRoleProps) => {
  const [userRole, setUserRole] = useState<USER_ROLE | null>(defaultRole);

  useEffect(() => setUserRole(defaultRole), [defaultRole]);

  useEffect(() => {
    if (userRole != null) {
      setNewUserRole(userRole);
    }
  }, [setNewUserRole, userRole]);

  const handleSubmit = useCallback(() => {
    if (userRole != null) {
      onSubmit(userRole);
    }
  }, [onSubmit, userRole]);

  useEffect(() => onRegisterSubmit(handleSubmit), [onRegisterSubmit, handleSubmit]);

  const userRoleOptions = [
    {
      id: USER_ROLE.INDIVIDUAL,
      type: USER_ROLE.INDIVIDUAL,
      caption: 'Best for executives, sales, recruiting, founders, and other professionals.',
      details: [],
    },
    {
      id: USER_ROLE.ASSISTANT,
      type: USER_ROLE.ASSISTANT,
      caption: "Best for assistants, admins, and others in supporting roles.",
      details: [],
    },
  ];

  return (
    <Box>
      <CabCardSelect<USER_ROLE>
        options={userRoleOptions}
        onChange={(v) => setUserRole(v)}
        value={userRole || undefined}
        title="User is using Cabinet..."
        individualTitle="For Themselves"
        assistantTitle="For Someone Else"
      />
    </Box>
  );
};

type UserLink = {
  assistant: number | null;
  leader: number | null;
};

interface AddUserLinkUsersProps {
  defaultUserLink: UserLink;
  onRegisterSubmit: (submitFunc: () => void) => void;
  onSubmit: (link: UserLink) => void;
  assistants: OrganizationLicense[];
  assistantsLeaders: { [id: number]: Leader[] };
}

const AddUserLinkAssistant = ({
  defaultUserLink, onRegisterSubmit, onSubmit, assistants, assistantsLeaders,
}: AddUserLinkUsersProps) => {
  const { control, handleSubmit, watch } = useForm({ defaultValues: defaultUserLink });
  const assistant = watch('assistant');

  useEffect(() => onRegisterSubmit(handleSubmit(onSubmit)), [onRegisterSubmit, handleSubmit, onSubmit]);

  return (
    <Stack spacing={2} marginTop={3}>
      <FormControl>
        <FormLabel>Does this user have an assistant on Cabinet?</FormLabel>
        <Controller control={control} name="assistant"
          rules={{ required: { value: true, message: 'Required' } }}
          render={({ field: { ref, ...field }, fieldState }) => (<>
            <CabDropdown
              placeholder="No"
              options={assistants.map(a => ({
                value: a.user || `org-license-${a.id}`,
                label: a.name || a.email,
                disabled: !a.user,
                tooltipText: a.user ? '' : 'Assistant may not be associated until they have created an account',
              }))}
              {...field}
              value={field.value || ''}
            />
            <FormHelperText error>{fieldState.error && fieldState.error.message}</FormHelperText>
          </>)}
        />
      </FormControl>
      {!!assistant && (
        <FormControl>
          <FormLabel>Does their assistant have an existing profile for them?</FormLabel>
          <Controller control={control} name="leader"
            render={({ field: { ref, ...field }, fieldState }) => (<>
              <CabDropdown
                placeholder="No, create a new one"
                options={assistantsLeaders[assistant]?.map(l => ({
                  value: l.id,
                  label: `${l.first_name} ${l.last_name}`
                })) || []}
                {...field} value={field.value || ''}
              />
              <FormHelperText error>{fieldState.error && fieldState.error.message}</FormHelperText>
            </>)}
          />
        </FormControl>
      )}

    </Stack>
  );
};

type IndividualLink = {
  individuals: number[];
};

interface AddUserLinkIndividualProps {
  defaultIndividualLink: IndividualLink;
  onRegisterSubmit: (submitFunc: () => void) => void;
  onSubmit: (link: IndividualLink) => void;
  individuals: OrganizationLicense[];
}

const AddUserLinkIndividual = ({
  defaultIndividualLink, onRegisterSubmit, onSubmit, individuals,
}: AddUserLinkIndividualProps) => {
  const { control, handleSubmit } = useForm({ defaultValues: defaultIndividualLink });

  useEffect(() => onRegisterSubmit(handleSubmit(onSubmit)), [onRegisterSubmit, handleSubmit, onSubmit]);

  const options = individuals.map(lic => ({
    ...lic,
    leader: lic.assists_leaders?.find(l => l.leader_user && l.leader_user === lic.user),
  })).map(l => ({
    value: l.leader?.id || l.id,
    label: l.leader ? `${l.leader.first_name} ${l.leader.last_name}` : l.name || l.email,
    disabled: !l.leader,
    tooltipText: l.leader ? '' : 'User may not be associated until they have created an account',
  }));

  const getPlaceholder = useCallback((value:  number[]) => (
    options.filter(option => !value.includes(option.value)).length === 0
      ? 'No available individuals' 
      : options.filter(option => value.includes(option.value)).length ? 
        "Add Another Supported Individual" : "No"
  ), [options]);

  return (
    <Stack spacing={2} marginTop={3}>
      <FormControl>
        {/* <FormLabel>Assign Individual</FormLabel> */}
        <Controller name="individuals" control={control} render={({ field }) => (
          <>
            {options.filter(option => field.value.includes(option.value)).length ? (
              <CabList
                items={options
                  .filter(option => field.value.includes(option.value)).map(({ value, label }) => ({
                    value,
                    label,
                    editable: true,
                    // disabled: !isOwner,
                    onDeleteClick: () => field.onChange(field.value.filter(v => v !== value)),
                  }))}
              />
            ) : <></>}
            <CabDropdown<number | string>
              placeholder={getPlaceholder(field.value)}
              disabled={options.filter(option => !field.value.includes(option.value)).length === 0}
              options={options.filter(option => !field.value.includes(option.value))}
              onChange={(e) => field.onChange(
                Array.from(new Set([...field.value, e.target.value as number]))
              )}
              value={''}
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
            />
          </>
        )} />
      </FormControl>
    </Stack>
  );
};
