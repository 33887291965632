import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { OrganizationLeader } from "../../../store";
import { ReactNode, useMemo } from "react";
import LeaderMenu from "./LeaderMenu/LeaderMenu";


export const useLeaderColumns = ({
  onSelectLeaderBackupInfo, onDeleteLeader, hasEditLeaderPermission, onEditLeader, onReassignLeader, canTransferLeader
}: {
  onSelectLeaderBackupInfo: (leader: OrganizationLeader) => void;
  onDeleteLeader: (leaderId: number) => void;
  hasEditLeaderPermission: boolean;
  onEditLeader: (leader: OrganizationLeader) => void;
  onReassignLeader: (leader: OrganizationLeader) => void;
  canTransferLeader: boolean;
}) => {
  return useMemo<GridColDef<OrganizationLeader>[]>(() => {
    const cols: GridColDef<OrganizationLeader>[] = [
      {
        headerName: 'Teammate',
        type: "string",
        field: 'name',
        align: "left",
        display: "flex",
        width: 300,
        editable: false,
        sortable: true,
        filterable: true,
        valueGetter: (value, row, column, apiRef) => (
          `${row.first_name} ${row.last_name} (${row.email || "No email"})`
        ),
      },
      {
        headerName: 'Title',
        type: "string",
        field: 'role',
        align: "left",
        display: "flex",
        editable: false,
        sortable: true,
        filterable: true,
        valueFormatter: (value, row, column, apiRef) => {
          return value || '';
        }
      },
      {
        headerName: 'Primary Support',
        type: "string",
        field: 'primary_assistant',
        align: "left",
        editable: false,
        sortable: true,
        filterable: true,
        width: 300,
        display: "flex",
        sortComparator: (
          v1: OrganizationLeader["primary_assistant"], v2 :OrganizationLeader["primary_assistant"], param1, param2
        ) => `${param1.value}`.localeCompare(param2.value),
        // ) => {
        //   return gridStringOrNumberComparator(
        //     v1.first_name,
        //     v2.first_name,
        //     param1,
        //     param2,
        //   );
        // },
        valueGetter: (value: OrganizationLeader["primary_assistant"], row, column, apiRef) => {
          if (!value) {
            return "No Primary Support Assigned";
          }
          const firstName = value.first_name;
          const lastName = value.last_name;
          const email = value.email;
          return `${firstName} ${lastName} (${email})`;
        }
      },
      {
        headerName: 'See More',
        type: "string",
        field: '',
        width: 200,
        editable: false,
        headerAlign: "center",
        sortable: false,
        align: "center",
        display: "flex",
        disableExport: true,
        renderCell: (props: GridRenderCellParams<OrganizationLeader, ReactNode>) => <>
          <LeaderMenu
            onSeeBackUps={() => onSelectLeaderBackupInfo(props.row)}
            onDeleteLeader={() => onDeleteLeader(props.row.id)}
            hasEditLeaderPermission={hasEditLeaderPermission}
            onEditLeader={() => onEditLeader(props.row)}
            onReassignLeader={() => onReassignLeader(props.row)}
            canTransferLeader={canTransferLeader}
            user={props.row.user_profile}
          />
        </>
      },
    ];

    return cols;
  }, [
    hasEditLeaderPermission, onDeleteLeader, onEditLeader, onReassignLeader, onSelectLeaderBackupInfo, canTransferLeader
  ]);
};
