import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { Auth, IdentityProvider } from '../../../store';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import PasswordRequirements from '../../../components/Common/PasswordRequirements';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CHANGE_EMAIL_GRAPHIC } from '../../../resourceUrls';
import { CabAvatar, CabButton, CabTextInput } from '@CabComponents';
import { Alert, Box, FormHelperText, FormLabel, Skeleton, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import colors from '../../../colors';
import CabSpinner from '@CabComponents/CabSpinner';
import { ALLOWED_IMG_EXTS, emailRegex } from '../../../constants';
import { setAuthErrorToSessionStorage } from '../../../utils/authUtils';
import { getIconSrc } from '../../../utils/renderUtils';

interface Props {
  auth: Auth,
  errors: string[];
  onValidatePassword: (currentPassword: string, newPassword: string, newPassword2: string) => boolean;
  onChangePassword: (currentPassword: string, newPassword: string) => Promise<boolean>;
  onSaveProfile: (
    profile: { firstName: string; lastName: string },
    profilePic: { file: File, fileName: string },
  ) => Promise<void>;
  logout: (global?: boolean) => void;
  onChangeEmail: (newEmail: string) => Promise<boolean>
}

interface ProfileFormInput {
  firstName: string;
  lastName: string;
  profilePic: { file: File, fileName: string },
}

const messageDuration = 3000;

export const Account = ({
  auth, errors, onValidatePassword,
  onChangePassword, onSaveProfile, logout,
  onChangeEmail
}: Props): ReactElement => {

  const renderErrors = () => {
    const errorText = errors.join('\n\n');

    return (
      <Box display='flex' justifyContent='flex-start' width='100%' marginTop={2}>
        <Slide direction="right" in={errors.length > 0}>
          <Box padding={1} marginBottom={1} minHeight={'30px'}>
            <Typography whiteSpace={'pre-wrap'} display="block" variant="body1" sx={{color: colors.redError}}>
              {errorText}
            </Typography>
          </Box>
        </Slide>
      </Box>
    );
  };

  return (
    <Box>
      <Profile auth={auth} onSaveProfile={onSaveProfile} />
      <ChangePassword
        auth={auth}
        onValidatePassword={onValidatePassword}
        onChangePassword={onChangePassword}
        logout={logout}
        renderErrors={renderErrors}

      />
      <ChangeEmail auth={auth} onChangeEmail={onChangeEmail} />
    </Box>
  );
};

export default Account;

export type ChangePasswordProps = {
  auth: Auth
  onValidatePassword: Props["onValidatePassword"]
  onChangePassword: Props["onChangePassword"],
  renderErrors: () => ReactNode
  logout: (global?: boolean) => void
};

const ChangePassword = ({
  auth,
  renderErrors,
  onValidatePassword,
  onChangePassword,
  logout
}: ChangePasswordProps) => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [confirmMessage, setConfirmMessage] = useState('');

  const handleValidPassword = (valid: boolean) => {
    setButtonDisabled(!valid);
  };

  const handleClickChangePassword = async () => {
    const passwordIsValid = onValidatePassword(currentPassword, newPassword, newPassword2);
    if (passwordIsValid) {
      setButtonDisabled(true);
      try {
        const success = await onChangePassword(currentPassword, newPassword);
        if (success) {
          setConfirmMessage('Your password has been changed!');
          setCurrentPassword('');
          setNewPassword('');
          setNewPassword2('');
          setTimeout(() => {
            setAuthErrorToSessionStorage([
              "Your password was changed successfully - please sign in with your new password."
            ]);
            logout(true);
          }, messageDuration);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setButtonDisabled(false);
      }
    }
  };

  return <>
    {auth.user?.identity_provider === IdentityProvider.COGNITO ?
      <Grid container spacing={4}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4} marginTop={4}>
          <Card variant="outlined" sx={{width: '100%'}}>
            <CardContent>
              <FormControl fullWidth={true}>
                <FormLabel>Current Password</FormLabel>
                <form>
                  {/* TODO: make this field stop autofilling! */}
                  <input type="hidden" value="prayer" />
                  <CabTextInput
                    id="currentPassword"
                    placeholder="Current Password"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    inputProps={{
                      required: true,
                      autoComplete: 'please-dont',
                      form: {
                        autoComplete: 'NO'
                      },
                    }}
                    fullWidth={true}
                    autoComplete="new-password"
                  />
                </form>
              </FormControl>
              <FormControl fullWidth={true} sx={{marginTop: 2}}>
                <FormLabel>New Password</FormLabel>
                <form>
                  <CabTextInput 
                    id="newPassword"
                    placeholder="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    inputProps={{ required: true, autoComplete: 'please-no' }}
                    fullWidth={true}
                    sx={{marginBottom: 2}}
                  />
                  <FormLabel>Confirm New Password</FormLabel>
                  <CabTextInput 
                    id="newPassword2"
                    placeholder="New Password"
                    type="password"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    inputProps={{ required: true, autoComplete: 'please-no' }}
                    fullWidth={true}
                  />
                </form>
              </FormControl>
              <PasswordRequirements password={newPassword} setValid={handleValidPassword} />
              <CabButton buttonType='primary' color='secondary' disabled={buttonDisabled}
                sx={{marginTop: 2}} onClick={handleClickChangePassword}>
                Change Password
              </CabButton>
              {renderErrors()}
              <Snackbar
                open={!!confirmMessage}
                onClose={() => setConfirmMessage('')}
                autoHideDuration={messageDuration}
                message={confirmMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
      :
      <Grid container spacing={4}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4} marginTop={4}>
          <Card variant="outlined" sx={{width: '100%'}}>
            <CardContent>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Box component='img' src={CHANGE_EMAIL_GRAPHIC} alt="Change Email" width='50%' />
                <Typography display="block" variant="body2" marginTop={2.5}>
                  You are logged in with {auth.user?.identity_provider === IdentityProvider.GOOGLE ?
                    "Google. Your password can be changed through your Google account."
                    :
                    "Company SSO. Your password can be changed by your company's IT team."
                  }
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
    }

  </>;
};

export type ChangeEmailProps = {
  auth: Auth
  onChangeEmail: Props["onChangeEmail"]
};

type ChangeEmailForm = {
  email: string
  email2: string
};

const ChangeEmail = ({ auth, onChangeEmail }: ChangeEmailProps) => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, getValues } = useForm<ChangeEmailForm>({
    defaultValues: {
      email: "",
      email2: ""
    }
  });

  const handleSubmitEmailChange = async (formData: ChangeEmailForm) => {
    setIsSubmitting(true);
    await onChangeEmail(formData["email"]);
    setIsSubmitting(false);
  };

  return <>
    {auth.user?.identity_provider === IdentityProvider.COGNITO ?

      <>
        <form onSubmit={handleSubmit(handleSubmitEmailChange)}>
          <Grid container spacing={4}>
            <Grid item xs={1} sm={3} md={4} />
            <Grid item xs={10} sm={6} md={4} marginTop={4}>
              <Card variant="outlined" sx={{width:'100%'}}>
                <CardContent>
                  <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%" gap={1}>
                      <Typography variant="h4">Change Your Email ({auth.user.email})</Typography>
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel>Email</FormLabel>
                        <Controller
                          control={control}
                          name="email"
                          rules={{ 
                            required: true,
                            validate: (e) => {
                              const isValidEmail = emailRegex.test(e);
                              if (!isValidEmail) {
                                return "Please enter a valid email";
                              }
                              return true;
                            }
                          }}
                          render={({ field, fieldState }) => {
                            return <><CabTextInput
                              {...field}
                            />
                            { fieldState.error?.message && <Alert 
                              color="error" sx={{marginTop: 1}}
                            >
                              {fieldState.error?.message}
                            </Alert>}
                            </>;
                          }}
                        />
                      </FormControl>
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel>Confirm Email</FormLabel>
                        <Controller
                          control={control}
                          name="email2"
                          rules={{ 
                            required: true,
                            validate: (e) => {
                              const isValidEmail = emailRegex.test(e);
                              if (!isValidEmail) {
                                return "Please enter a valid email";
                              }
                              const email = getValues("email");
                              return email === e ? true : "Confirm Email doesn't match Email";
                            }
                          }}
                          render={({ field, fieldState }) => {
                            return <><CabTextInput
                              {...field}
                            />
                            { fieldState.error?.message && <Alert 
                              color="error"
                              sx={{marginTop: 1}}
                            >
                              {fieldState.error?.message}
                            </Alert>}
                            </>;
                          }}
                        />
                      </FormControl>
                      <CabButton sx={{marginTop: 2}} type='submit'>
                        {isSubmitting ? <CabSpinner scale={1} color="info" /> : 'Change Email'}
                      </CabButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={1} sm={3} md={4} />
          </Grid>
          
        </form>
      </>
      :
      <Grid container spacing={4}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4} marginTop={4}>
          <Card variant="outlined" sx={{width: '100%'}}>
            <CardContent>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Box component='img' src={CHANGE_EMAIL_GRAPHIC} alt="Change Email" width='50%' />
                <Typography display="block" variant="body2" marginTop={2.5}>
                  Need to change your e-mail address? Send an email to&nbsp;
                  <a href="mailto:help@joincabinet.com">help@joincabinet.com</a> and someone from our
                  team will help you with that.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
    }
  </>;
};

export type ProfileProps = {
  auth: Auth
  onSaveProfile: (
    profile: { firstName: string; lastName: string },
    profilePic: { file: File, fileName: string },
  ) => Promise<void>;
};

const Profile = ({
  auth, onSaveProfile
}: ProfileProps) => {
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const theme = useTheme();

  const defaultValues = useMemo(() => ({
    firstName: auth.user?.first_name || '',
    lastName: auth.user?.last_name || '',
  }), [auth.user?.first_name, auth.user?.last_name]);

  const fullName = `${auth.user ? auth.user.first_name : ''} ${auth.user ? auth.user.last_name : ''}`;

  const {
    control, reset, setValue, handleSubmit: handleFormSubmit, formState: { isSubmitting }
  } = useForm<ProfileFormInput>({ defaultValues });

  const handleCancelProfile = () => {
    setShowProfileForm(false);
    reset(defaultValues);
  };


  const handleSubmitProfile = async ({ firstName, lastName, profilePic }: ProfileFormInput) => {
    setShowProfileForm(false);
    await onSaveProfile({ firstName, lastName }, profilePic);
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file.size > 2500000) {
      setErrorMessage("Max file size is 2.5Mb");
    } else if (!["image/png", "image/jpeg"].includes(file.type)) {
      setErrorMessage("Supported file types include png & jpeg");
    } else {
      setErrorMessage(null);
      setProfilePicUrl(URL.createObjectURL(file));
      setValue('profilePic', { file, fileName: file.name });
    }
  };

  return <Grid container spacing={4}>
    <Grid item xs={1} sm={3} md={4} />
    <Grid item xs={10} sm={6} md={4} marginTop={4}>
      <Card variant="outlined" sx={{width: '100%'}}>
        <CardContent>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            {auth.user?.profile ? (
              showProfileForm ? (
                <form onSubmit={handleFormSubmit(handleSubmitProfile)}>
                  <Dropzone onDrop={onDrop} accept={{"": ALLOWED_IMG_EXTS}} useFsAccessApi={false}>
                    {({ getRootProps, getInputProps }) => (
                      <Box display="flex" flexDirection="row" gap={2} padding={2} marginBottom={1}
                        border={`1px solid ${colors.black200}`} borderRadius={1}
                        {...getRootProps()} sx={{ cursor: 'pointer' }}
                      >
                        <CabAvatar
                          name={`${auth.user?.first_name} ${auth.user?.last_name}`}
                          src={profilePicUrl || getIconSrc(auth?.user?.profile.pic_url)}
                          color={theme.palette.primary.main}
                          size="large"
                          sx={{height: '80px', width: '80px'}}
                        />
                        <Box>
                          <input {...getInputProps()} />
                          <Typography>
                            Drag and drop file or <Box component="span" sx={{ textDecoration: 'underline' }}>
                              click here
                            </Box>
                          </Typography>
                          <Typography variant="caption" color={colors.black800} fontSize={12}>
                            JPG, GIF or PNG. Max size of 2.5Mb
                          </Typography>
                          {errorMessage &&
                            <FormHelperText error>{errorMessage}</FormHelperText>
                          }
                        </Box>
                      </Box>
                    )}
                  </Dropzone>

                  <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" gap={1}>
                    <FormControl sx={{ flex: 1 }}>
                      <FormLabel>First Name</FormLabel>
                      <Controller name="firstName" control={control} rules={{ required: true }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <>
                            <CabTextInput {...field} />
                            <FormHelperText error>{error && 'First name is required'}</FormHelperText>
                          </>
                        )}
                      />
                    </FormControl>

                    <FormControl sx={{ flex: 1 }}>
                      <FormLabel>Last Name</FormLabel>
                      <Controller name="lastName" control={control} rules={{ required: true }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <>
                            <CabTextInput {...field} />
                            <FormHelperText error>{error && 'Last name is required'}</FormHelperText>
                          </>
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                    <CabButton
                      buttonType="secondary"
                      onClick={handleCancelProfile}
                      sx={{marginTop: 2}}
                    >
                      Cancel
                    </CabButton>
                    <CabButton sx={{marginTop: 2}} type="submit">
                      Save Profile
                    </CabButton>
                  </Box>
                </form>
              ) : (
                <>
                  <CabAvatar
                    name={`${auth.user?.first_name} ${auth.user?.last_name}`}
                    src={getIconSrc(auth?.user?.profile.pic_url)}
                    color={theme.palette.primary.main}
                    size="large"
                    sx={{ marginBottom: 2, height:'80px', width: '80px' }}
                  />

                  <Typography variant="h1" component="h3" sx={{ marginTop: 1 }}>{fullName}</Typography>
                </>
              )
            ) : (
              <>
                <Skeleton width={200} height={40} sx={{ marginTop: 1 }} />
              </>
            )}
            {!showProfileForm && (
              <CabButton sx={{marginTop: 2}} onClick={() => setShowProfileForm(true)}>
                {isSubmitting ? <CabSpinner scale={1} color="info" /> : 'Edit Profile'}
              </CabButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={1} sm={3} md={4} />
  </Grid>;
};
