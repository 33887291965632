import { ReactElement, createRef, ChangeEvent, useState, useEffect } from 'react';
import loadImage from 'blueimp-load-image';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Box, styled } from '@mui/material';
import CabAvatar from '@CabComponents/CabAvatar';
import { CabIcon } from '@CabComponents/CabIcon';
import { useDispatch } from 'react-redux';
import { IoCloudUploadOutline, IoCloseCircle } from 'react-icons/io5';
import { Leader, OrganizationLeader, ThunkDispatchType, actions } from '../../../../store';
import { FixedNoteField } from '../../../../components/InfoCard/EditNote/EditNote';
import { ALLOWED_IMG_EXTS, ERROR_TYPE, EVENT_TYPE } from '../../../../constants';
import { trackEvent } from '../../../../utils/appAnalyticsUtils';
import { validateEmail, addFieldError, removeFieldError, FieldErrors } from '../../../../utils/inputUtils';

interface ComponentProps {
  leader: OrganizationLeader;
  isOpen: boolean;
  onDidDismiss: () => void;
}


type FieldKey = 'first_name'|'last_name'|'company'|'role'|'email'|'phone_number';

type Props = ComponentProps;

export const AdminEditLeader = 
  ({ leader, isOpen, onDidDismiss}: Props): ReactElement => {

    const dispatch = useDispatch<ThunkDispatchType>();
    const updateLeader = (
      leaderUpdate: Partial<Leader> & { id: number }, 
      leaderPic: File | null, 
      removeLeaderPic?: boolean,
    ) => dispatch(actions.leaders.updateAdminLeader(leaderUpdate, leaderPic, removeLeaderPic));

    const picInputRef = createRef<HTMLInputElement>();
    const [leaderPic, setLeaderPic] = useState<{img: File | null; name: string | null}>({img: null, name: null});
    const [leaderPicUrl, setLeaderPicUrl] = useState<string>(leader.pic_url || "");
    const [removeLeaderPic, setRemoveLeaderPic] = useState(false);
    const [isPicHovering, setIsPicHovering] = useState(false);

    const [editedLeader, setEditedLeader] = useState<OrganizationLeader>(leader);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
    const [formError, setFormError] = useState<string | null>(null);

    useEffect((): void => {
      if (isOpen) {
        setLeaderPicUrl(leader.pic_url || "");
      }
    }, [leader.pic_url, isOpen]);

    useEffect((): void => {
      if (isOpen) {
        setEditedLeader(leader);
        setFieldErrors({ });
      }
    }, [leader, isOpen]);

    const clearImage = (): void => {
      setLeaderPic({img: null, name: null});
    };

    const handleUploadImageClick = (): void => {
      if (picInputRef.current) {
        picInputRef.current.click();
      }
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>): void => {
      if (e.target && e.target.files) {
        const orig_img: File = e.target.files[0];
  
        loadImage(orig_img, (canvas): void => {
          if (canvas instanceof HTMLCanvasElement) {
            const url = canvas.toDataURL('image/png');
            setLeaderPicUrl(url);
            setLeaderPic({img: orig_img, name: orig_img.name});
            setRemoveLeaderPic(false);
          }
        }, {canvas: true, orientation: true, maxWidth: 200, maxHeight: 200, crop: true});
      }
    };

    const handleRemoveImage = (): void => {
      setLeaderPicUrl("");
      // Setting name to empty string explicitly indicates (in the action creator) that the image has been removed
      //   Null is the default state, indicating that no changes has been made.
      //   This isn't the cleanest way to do this, but is simple and doesn't require extra state
      setLeaderPic({img: null, name: ""});
      setRemoveLeaderPic(true);
    };

    const handleEmailChange = (email: string): void => {
      if (validateEmail(email)) {
        handleRemoveError('email', ERROR_TYPE.EMAIL);
      } else {
        handleAddError('email', ERROR_TYPE.EMAIL);
      }
    };

    const validateRequiredFieldChange = (fieldKey: FieldKey): ((newValue: string) => void) => {
      return (newValue: string): void => {
        if (newValue === '') {
          handleAddError(fieldKey, ERROR_TYPE.REQUIRED);
        } else {
          handleRemoveError(fieldKey, ERROR_TYPE.REQUIRED);
        }
      };
    };

    const changeFunc = (key: keyof OrganizationLeader) => (value: string): void => {
      setEditedLeader((prev) => ({...prev, [key]: value}));
    };

    const handleAddError = (fieldKey: FieldKey, errorType: keyof typeof ERROR_TYPE): void => {
      setFieldErrors(addFieldError(fieldErrors, fieldKey, errorType));
    };
  
    const handleRemoveError = (fieldKey: FieldKey, errorType: keyof typeof ERROR_TYPE): void => {
      setFieldErrors(removeFieldError(fieldErrors, fieldKey, errorType));
    };

    const getErrors = () => {
      const fieldMessages: string[] = [];
      Object.values(fieldErrors).forEach(field => {
        Object.values(field).forEach((error): void => {
          if (!error.valid) {
            fieldMessages.push(error.message);
          }
        });
      });

      return fieldMessages.join('');
    };

    const handleCancel = (): void => {
      onDidDismiss();
    };

    const handleSave = (): void => {
      const errors = getErrors();
      if (!errors) {
        const tempLeader: Partial<Leader> & {id: number} = {
          ...editedLeader,
          primary_assistant: editedLeader.primary_assistant?.id,
        };
        updateLeader(tempLeader, leaderPic.img, removeLeaderPic).then((): void => {
          clearImage();
          dispatch(actions.leaders.fetchLeaders());
          onDidDismiss();
        });
        trackEvent(EVENT_TYPE.UPDATE_EXEC);
      } else {
        setFormError(errors);
      }
    };

    return (
      <>
        <CabModal
          open={isOpen}
          onClose={handleCancel}
          closeIcon
          closeOnBackdropClick
          title={`Edit ${leader.first_name} ${leader.last_name}`}
          actionButtons={
            <CabButton color='primary' onClick={handleSave}>
              Save
            </CabButton>
          }
        >
          <Box display='flex' flexDirection='column'>
            <CabButton sx={{width: "100%"}} buttonType="text" onClick={handleUploadImageClick}
              overrides={{onMouseEnter: () => setIsPicHovering(true), onMouseLeave: () => setIsPicHovering(false)}}
            >
              <div tabIndex={0}></div>
              <HiddenInput type="file" id="file" ref={picInputRef} 
                onChange={handleChangeImage} accept={ALLOWED_IMG_EXTS.join(",")}/>
              <AvatarContainer>
                <CabAvatar
                  color={leader.color}
                  size="large"
                  src={leaderPicUrl}
                  name={`${leader.first_name} ${leader.last_name}`}
                  sx={{opacity: isPicHovering ? 0.5 : 1}}
                />
                {isPicHovering &&
                  <Box sx={{position: "absolute", top: "28px"}}>
                    <CabIcon Icon={IoCloudUploadOutline} sx={{fontSize: 42}}/>
                  </Box>
                }
              </AvatarContainer>
            </CabButton>
            <Box display={"flex"} justifyContent={"center"}>
              <CabButton buttonType='text' color='primary' size='small'
                onClick={handleRemoveImage} disabled={!leaderPicUrl}
                icon={<CabIcon alt='Delete' Icon={IoCloseCircle} />}
              >
                Remove Photo
              </CabButton>
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
              <FixedNoteField fieldLabelKey="first_name" placeholder="Enter a name" 
                value={editedLeader.first_name} changeFunc={changeFunc('first_name')}
                validateField={validateRequiredFieldChange('first_name')}/>

              <FixedNoteField fieldLabelKey="last_name" placeholder="Enter a name" 
                value={editedLeader.last_name} changeFunc={changeFunc('last_name')} 
                validateField={validateRequiredFieldChange('last_name')}/>

              <FixedNoteField fieldLabelKey="company" placeholder="Company" 
                value={editedLeader.company} changeFunc={changeFunc('company')} />

              <FixedNoteField fieldLabelKey="role" placeholder="Job title" 
                value={editedLeader.role} changeFunc={changeFunc('role')} />

              <FixedNoteField fieldLabelKey="email" placeholder="Email" 
                value={editedLeader.email} changeFunc={changeFunc('email')} 
                validateField={handleEmailChange}/>

              <FixedNoteField fieldLabelKey="phone_number" placeholder="Phone number" 
                value={editedLeader.phone_number} changeFunc={changeFunc('phone_number')}/>
            </Box>
          </Box>
        </CabModal>

        <CabModal
          open={!!formError}
          isAlert
          closeOnBackdropClick
          noFullScreen={true}
          onClose={(): void => setFormError(null)}
          title={'Errors'}
          text={formError || ''}
          actionButtons={
            <CabButton buttonType='primary' color='primary' onClick={(): void => setFormError(null)}>
              Okay
            </CabButton>
          }
        />
      </>
    );
  };
  
const AvatarContainer = styled('div', {label: "AvatarContainer"})(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "4px",
  marginBottom: "4px",
}));

const HiddenInput = styled('input', {label: "HiddenInput"})(({theme}) => ({
  display: "none"
}));

export default AdminEditLeader;